/* eslint-disable camelcase */
import { useMediaQuery } from '@react-hookz/web';
import clx from 'classnames';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import CustomMarkdown from '@/molecules/CustomMarkdown';
import WidgetCTA from 'components/widgets/components/WidgetCTA';
import { POSITION_ENUM, SIZE } from 'types/utils';
import { CMSCallToActionType, CmsImageType, CmsVideoType } from 'utils/cms/types';

enum SIDE_IMAGE_SIZE {
  '1/3' = '1/3',
  '2/3' = '2/3',
  '0/3' = '0/3',
  '1/2' = '1/2',
}

export type ContentImageBlockProps = {
  title: string;
  text: string;
  on_title?: string;
  text_position: 'left' | 'right';
  side_image?: CmsImageType;
  side_video?: CmsVideoType;
  side_image_desktop?: CmsImageType;
  background_image?: CmsImageType;
  background_image_desktop?: CmsImageType;
  side_image_self_align: POSITION_ENUM;
  side_image_size: SIDE_IMAGE_SIZE;
  cta?: CMSCallToActionType;
  spacing: SIZE | 'none';
  hide_side_image_mobile?: boolean;
  id: string;
  content_list?: {
    icon: string;
    lines: Array<{ line_text: string }> | false;
    lines_position: 'before' | 'after';
  };
};

const SPACING_TO_CSS = {
  [SIZE.SM]: 'py-4',
  [SIZE.MD]: 'py-8',
  [SIZE.LG]: 'py-16 lg:py-20',
  none: 'py-0',
};

const IMAGE_SIZE_TO_IMAGE_CLASSNAME = {
  [SIDE_IMAGE_SIZE['1/2']]: 'md:basis-1/2 w-full',
  [SIDE_IMAGE_SIZE['1/3']]: 'md:basis-1/3 w-full',
  [SIDE_IMAGE_SIZE['2/3']]: 'md:basis-2/3 w-full',
  [SIDE_IMAGE_SIZE['0/3']]: 'basis-0',
};
const IMAGE_SIZE_TO_TEXT_CLASSNAME = {
  [SIDE_IMAGE_SIZE['1/2']]: 'basis-1/2',
  [SIDE_IMAGE_SIZE['1/3']]: 'basis-2/3',
  [SIDE_IMAGE_SIZE['2/3']]: 'basis-1/3',
  [SIDE_IMAGE_SIZE['0/3']]: 'basis-full',
};

const IMAGE_SELF_ALIGN_TO_CLASSNAME = {
  [POSITION_ENUM.bottom]: 'self-end',
  [POSITION_ENUM.center]: 'self-center',
  [POSITION_ENUM.top]: 'self-start',
  [POSITION_ENUM.left]: 'self-center flex place-content-start',
  [POSITION_ENUM.right]: 'self-center flex place-content-end',
  [POSITION_ENUM['left-top']]: 'self-start flex place-content-start',
  [POSITION_ENUM['right-top']]: 'self-start flex place-content-end',
  [POSITION_ENUM['left-bottom']]: 'self-end flex place-content-start',
  [POSITION_ENUM['right-bottom']]: 'self-end flex place-content-end',
};

const ContentImageBlock = (props: ContentImageBlockProps) => {
  const {
    title,
    text,
    text_position: textPosition,
    side_image: sideImage,
    side_video: sideVideo,
    side_image_desktop: sideImageDesktop,
    hide_side_image_mobile: hideSideImageOnMobile,
    background_image: backgroundImage,
    background_image_desktop: backgroundImageDesktop,
    on_title,
    side_image_self_align,
    side_image_size,
    cta,
    spacing,
    content_list: contentList,
    id,
  } = props;
  const isDesktop = useMediaQuery('(min-width: 1024px)', { initializeWithValue: false });

  const initialTranslate = useMemo(() => {
    if (!isDesktop) {
      return -16;
    }
    if (textPosition === 'right') {
      return 16;
    }

    return -16;
  }, [isDesktop, textPosition]);

  return (
    <div className="h-fit relative text-neutral-800">
      <div
        className="h-full w-full absolute bg-cover bg-center"
        style={{
          ...(!!backgroundImage && {
            backgroundImage:
              backgroundImageDesktop && isDesktop
                ? `url('${backgroundImageDesktop.url}')`
                : `url('${backgroundImage.url}')`,
          }),
        }}
      />
      <div
        className={clx(
          'flex flex-col lg:flex-row items-center mx-auto bg-cover relative max-w-1200 px-4 xl:px-0 gap-6',
          textPosition === 'left' && 'lg:flex-row-reverse',
          SPACING_TO_CSS[spacing],
        )}
      >
        {typeof isDesktop === 'boolean' && (
          <motion.div
            className={clx(
              'flex flex-col gap-1 self-start',
              IMAGE_SIZE_TO_TEXT_CLASSNAME[side_image_size],
              backgroundImage && 'bg-sand-300/80 backdrop-blur shadow-raised p-4 rounded-lg my-8 mx-2',
            )}
            initial={{ translateX: initialTranslate * -1, opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeOut', delay: 0.2 }}
            viewport={{ once: true }}
            whileInView={{ translateX: 0, opacity: 1 }}
          >
            {on_title && <h5 className="title-5 text-neutral-400">{on_title}</h5>}
            {title && <h3 className="title-4 mb-1">{title}</h3>}
            <div
              className={clx(contentList?.lines_position === 'before' ? 'flex-col-reverse' : 'flex-col', 'flex gap-4')}
            >
              {text && <CustomMarkdown>{text}</CustomMarkdown>}

              {Array.isArray(contentList?.lines) && (
                <ul className="flex flex-col gap-2">
                  {contentList?.lines.map(({ line_text: lineText }) => (
                    <li key={lineText} className="flex md-medium text-neutral-900 gap-2 items-baseline">
                      <i className={clx(contentList.icon, 'text-primary-500 text-sm')} /> {lineText}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {cta?.link && <WidgetCTA className="m-auto mt-4" cta={cta} widgetId={id} />}
          </motion.div>
        )}
        {sideImage && typeof isDesktop === 'boolean' && !sideVideo && (
          <motion.picture
            className={clx(
              IMAGE_SIZE_TO_IMAGE_CLASSNAME[side_image_size],
              IMAGE_SELF_ALIGN_TO_CLASSNAME[side_image_self_align],
              !!hideSideImageOnMobile && 'hidden lg:block',
            )}
            initial={{ translateX: initialTranslate, opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeOut', delay: 0.2 }}
            viewport={{ once: true }}
            whileInView={{ translateX: 0, opacity: 1 }}
          >
            {sideImageDesktop && <source media="(min-width: 1024px)" srcSet={sideImageDesktop.url} />}
            <img alt={sideImage.alt} className="rounded-lg shadow-overlap w-full" src={sideImage.url} />
          </motion.picture>
        )}
        {sideVideo && typeof isDesktop === 'boolean' && (
          <video className="rounded-lg shadow-overlap" autoPlay loop muted>
            <source src={sideVideo.url} type="video/webm" />
          </video>
        )}
      </div>
    </div>
  );
};

export default ContentImageBlock;
